.text-editor {
  &.heading {
    .scrollable {
      height: $page-content-height;
      max-height: $page-content-height;
      @include font(Verlag Light);
      font-size: 18px;
      line-height: 25px;

      @media (max-width: $grid-float-breakpoint-max) {
        height: auto;
        max-height: none;
      }
    }
  }
}