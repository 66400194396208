.#{$panel-selector} {
  position: absolute;
  top: 0;
  bottom: $footer-height;
  left: 0;
  width: 100%;
  height: $content-area-height;
  visibility: hidden;
  @include transition(visibility 0s ($panel-transition-duration * 2));
  z-index: 11;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    cursor: pointer;
    @include transition(background $panel-transition-duration $panel-transition-duration);
  }

  &.is-visible {
    visibility: visible;
    @include transition(visibility 0s 0s);

    &:after {
      background: rgba(0, 0, 0, ($panel-transition-duration * 2));
      @include transition(background $panel-transition-duration 0s);
    }

    .#{$panel-selector}-header {
      top: 0;
      @include transition(top $panel-transition-duration $panel-transition-duration);
    }

    .#{$panel-selector}-container {
      @include translate3d(0, 0, 0);
      @include transition-delay(0s);
    }
  }

  &-header {
    position: absolute;
    width: 90%;
    height: 80px;
    top: -150px;
    left: 0;
    line-height: 100px;
    background: transparent;
    z-index: 2;
    @include transition(top $panel-transition-duration 0s);
    padding: 0 30px;

    .back-button {
      cursor: pointer;
    }

    .browse {
      float: right;
      @include user-select(none);

      .previous, .next {
        @include font(Verlag Book);
        color: #ffffff;
        font-size: 15px;
        text-decoration: none;
        padding: 0 10px;
        cursor: pointer;
        @include opacity(1);
        @include transition(opacity .3s ease-in-out);

        &.disabled {
          @include opacity(0.5);
          cursor: not-allowed;
        }
      }

      .previous:before,
      .next:after {
        font-family: FontAwesome;
        color: #ffffff;
      }

      .previous:before {
        content: '\f0d9';
        padding-right: 5px;
      }

      .next:after {
        content: '\f0da';
        padding-left: 5px;
      }
    }

    @media (max-width: $grid-float-breakpoint-max) {
      padding: 0 15px;
    }
  }

  &-container {
    position: relative;
    width: 90%;
    height: $content-area-height;
    left: 0;
    background: #464646;
    padding: 100px 30px 0 30px;
    z-index: 1;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    @include translate3d(-100%, 0, 0);
    @include transition-duration($panel-transition-duration);
    @include transition-delay($panel-transition-duration);

    .scrollable {
      height: $page-list-scrollable-content-height;
      max-height: $page-list-scrollable-content-height;

      @media (max-width: $grid-float-breakpoint-max) and (max-height: 598px) {
        height: (($page-responsive-fixed-height) * 2 - 120px);
        max-height: (($page-responsive-fixed-height) * 2 - 120px);
      }
    }

    .title {
      color: #ff6633;
      padding-right: 40px;
      margin-bottom: 20px;

      @media (max-width: $grid-float-breakpoint-max) {
        padding-right: 5px;
      }
    }

    .page-image {
      display: block;
      width: 100%;
      height: auto;
      margin: 0 0 20px 0;
      padding-right: 40px;

      @media (max-width: $grid-float-breakpoint-max) {
        padding-right: 10px;
      }
    }

    .#{$panel-selector}-content {
      padding-right: 40px;
      -webkit-overflow-scrolling: touch;

      p {
        @include font(Verlag XLight);
        font-size: 18px;
        color: #ffffff;

        &:first-of-type {
          margin-top: 0;
        }
      }

      @media (max-width: $grid-float-breakpoint-max) {
        padding-right: 10px;
      }
    }

    @media (max-width: $grid-float-breakpoint-max) and (max-height: 598px) {
      height: ($page-responsive-fixed-height * 2);
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &-header,
  &-container {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
      width: percentage(7 / 12);
    }

    @media (min-width: $screen-md-min) {
      width: percentage(5 / 12);
    }
  }

  @media (max-width: $grid-float-breakpoint-max) and (max-height: 598px) {
    height: ($page-responsive-fixed-height * 2);
  }
}
