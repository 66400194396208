@each $font-name, $file-name, $font-weight in ('Verlag XLight', 'Verlag-XLight', 200),
        ('Verlag Light', 'Verlag-Light', 300),
        ('Verlag Book', 'Verlag-Book', normal) {

  @font-face {
    font-family: '#{$font-name}';
    src: url('../fonts/#{$file-name}.eot');
    src: url('../fonts/#{$file-name}.eot?#iefix') format('embedded-opentype'),
    url('../fonts/#{$file-name}.woff2') format('woff2'),
    url('../fonts/#{$file-name}.woff') format('woff'),
    url('../fonts/#{$file-name}.ttf') format('truetype'),
    url('../fonts/#{$file-name}.svg##{$file-name}') format('svg');
    font-weight: #{$font-weight};
    font-style: normal;
  }

}