@media (min-width: $grid-float-breakpoint) {
  .lg-backdrop,
  .lg-outer .lg {
    height: $content-area-height !important;
  }

  .lg-backdrop,
  .lg-outer {
    top: calc(#{$navbar-height} + 1px) !important;
  }
}

.lg-actions .lg-next,
.lg-actions .lg-prev {
  background: transparent;
  @include carousel-custom-offset(-5px);

  .slick-prev,
  .slick-next {
    margin-top: 0;
  }

  &.lg-icon {
    &:before,
    &:after {
      display: none;
    }
  }
}

.lg-toolbar {
  background-color: transparent;
}

.file-manager {
  .carousel.zoomable .carousel-item,
  .bootstrap-carousel .item {
    $zoom-icon-size: 32px;

    figure.zoom {
      position: absolute;
      right: 15px;
      bottom: 15px;
      display: block;
      background: url('../images/zoom.png') no-repeat;
      background-size: $zoom-icon-size;
      width: $zoom-icon-size;
      height: $zoom-icon-size;
      text-indent: -9999px;
      opacity: 0;
      cursor: pointer;
      @include transition(opacity .3s ease-in-out);
    }

    &:hover {
      figure.zoom {
        opacity: 1;
      }
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 5px;
      right: 56px;
      background: url('../images/zoomable-gallery-logo.png') no-repeat;
      background-size: 160px;
      background-position: bottom right;
      width: 259px;
      height: 78px;
      @include opacity(0.7);

      @media (max-width: $screen-xs-max) {
        display: none !important;
      }
    }
  }

  &.references {
    .carousel {
      width: calc(100vw - 100px);
      margin-bottom: 0;
      @include carousel-custom-arrow(orange);

      &-item {
        margin: 0;
        padding: 5px;
        @include outline-none();

        .logo {
          background-color: #ffffff;
          text-align: center;
          overflow-y: hidden;
          height: calc(100vh / 5);
          line-height: calc(100vh / 5);

          img {
            @include img-responsive(inline-block);
          }

          .grayscale {
            @include opacity(0.75);
          }

          .grayscale:hover {
            @include opacity(1);
          }
        }
      }
    }

    @media (max-height: 699px) {
      .page-heading {
        margin-bottom: 0;
      }
    }
  }

  &.certificates {
    .carousel {
      width: calc(60vw - 100px);
      margin-bottom: 0;
      @include carousel-custom-arrow(orange);
      @include carousel-custom-offset(-25px);

      @media (max-width: $grid-float-breakpoint-max) {
        width: 100vw;
        margin: 30px 0;
      }

      .certificate {
        margin: 0 20px;

        img {
          @include img-responsive(block);
        }

        @media (max-width: $grid-float-breakpoint-max) {
          margin: 0 40px;
        }
      }

      @media (max-width: $grid-float-breakpoint-max) {
        @include carousel-custom-offset(10px);
        @include carousel-custom-arrow(orange);
      }
    }
  }

  &.subpage_carousel {
    .carousel img {
      width: 100%;
      height: auto;
    }

    .carousel-navigation img {
      @include img-responsive(block);
    }

    .carousel {
      width: calc(60vw - 100px);

      .carousel-item {
        cursor: pointer;

        img {
          @include transition(transform .7s ease-in-out);
        }

        &:hover  > img {
          @include scale(1.2);
        }
      }

      @media (max-width: $grid-float-breakpoint-max) {
        width: 100vw;
        margin-bottom: 0;
        @include carousel-custom-arrow(white);
        @include carousel-custom-offset(15px);
      }

      @media screen and (max-height: 699px) and (min-width: ($screen-lg + 200px)) {
        @include scale(0.93);
        margin-bottom: 0;
      }
    }

    .carousel-navigation {
      width: calc(60vw - 100px);
      @include carousel-custom-arrow(orange);
      @include carousel-custom-offset(-30px);

      .carousel-navigation-item {
        position: relative;
        margin-left: 10px;
        margin-right: 10px;

        &:not(.slick-current) {
          @include overlay(#2e2e2e, 0.8);
        }
      }

      @media (max-width: $grid-float-breakpoint-max) {
        display: none !important;
      }

      @media (max-height: 699px) and (min-width: ($screen-lg + 200px)) {
        @include scale(0.93);

        .slick-dots {
          bottom: -45px;
        }
      }
    }
  }

  &.dynamic_list {
    @include carousel-custom-offset(20px);

    .carousel-inner .item {
      height: $content-area-height;
      max-height: $content-area-height;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      cursor: pointer;
      @include transition(transform .6s ease-in-out);

      &:hover {
        @include scale(1.2);
      }

      @media (max-width: $grid-float-breakpoint-max) and (max-height: 598px) {
        height: 600px;
        max-height: 600px;
      }
    }
  }

  &.absolute_background {
    .absolute {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
      height: $content-area-height;
      background: no-repeat center;
      background-size: cover;
      z-index: -1;

      @media (max-width: $grid-float-breakpoint-max) and (max-height: 598px) {
        height: 600px;
      }
    }
  }

  &.homepage_animation {
    video {
      object-fit: cover;
      width: 100%;
      height: calc(100vh - 110px);
      position: absolute;
      z-index: -2;

      @media (max-width: $grid-float-breakpoint-max) {
        display: none;
      }
    }

    .mobile-fallback {
      padding: 20px 15px;
      @include clearfix();

      img {
        display: block;
        float: left;
        width: 50vw;
        @include animation-duration(2s);
      }

      .motto-container {
        position: absolute;
        right: 0;
        top: 50%;
        @include translate3d(0, -50%, 0);

        span {
          display: block;

          &.motto-upper {
            font-size: 7vw;
            @include font(Verlag XLight);
            @include animation-delay(1s);
            @include animation-duration(2s);
          }

          &.motto-bottom {
            position: relative;
            right: 15px;
            font-size: 6vh;
            padding-left: 15vw;
            line-height: 4vh;
            @include font(Verlag Book);
            @include animation-delay(2s);
            @include animation-duration(2s);
          }
        }
      }

      @media (min-width: $grid-float-breakpoint) {
        display: none;
      }
    }
  }
}