.sidr {
  // Default Settings
  display: block;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 999999;
  width: $sidebar-width;
  overflow-x: hidden;
  overflow-y: auto;

  .sidr-inner {
    padding: 0 0 15px;

    > p {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  &.right {
    left: auto;
    right: $sidebar-width-neg;
  }

  &.left {
    left: $sidebar-width-neg;
    right: auto;
  }

  // Theme Settings
  font-family: $sidr-font-family;
  font-size: $sidr-font-size;
  background: $sidr-background;
  color: $sidr-text-color;
  box-shadow: 0 0 5px 5px $sidr-background-shadow-color inset;
  text-transform: uppercase;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: $sidr-font-size - 4;
    font-weight: normal;
    padding: 0 15px;
    margin: 0 0 5px;
    color: $sidr-text-color;
    line-height: 24px;
    box-shadow: 0 5px 5px 3px rgba($sidr-box-shadow-color, .2);
  }

  p {
    font-size: $sidr-font-size - 2;
    margin: 0 0 12px;

    a {
      color: rgba($sidr-text-color, .9);
    }
  }

  > p {
    margin-left: 15px;
    margin-right: 15px;
  }

  ul {
    display: block;
    margin: 0 0 15px;
    padding: 0;
    border-top: 1px solid darken($sidr-background, 10%);
    border-bottom: 1px solid lighten($sidr-background, 10%);

    li {
      display: block;
      margin: 0;
      line-height: 48px;
      border-top: 1px solid lighten($sidr-background, 10%);
      border-bottom: 1px solid darken($sidr-background, 10%);

      &.active,
      &.sidr-class-active {
        border-top: 0;
        line-height: 49px;

        > a,
        > span {
          box-shadow: 0 0 15px 3px $sidr-background-shadow-color inset;
        }
      }

      &.catalogue {
        > a > span {
          padding: 0 !important;
        }
      }

      a,
      span {
        padding: 0 15px;
        display: block;
        text-decoration: none;
        color: $sidr-text-color;
      }

      ul {
        border-bottom: 0;
        margin: 0;

        li {
          line-height: 40px;
          font-size: $sidr-font-size - 2;

          &:last-child {
            border-bottom: 0;
          }

          &:hover,
          &.active,
          &.sidr-class-active {
            border-top: 0;
            line-height: 41px;

            > a,
            > span {
              box-shadow: 0 0 15px 3px $sidr-background-shadow-color inset;
            }
          }

          a,
          span {
            color: rgba($sidr-text-color, .8);
            padding-left: 30px;
          }
        }
      }
    }
  }

  form {
    margin: 0 15px;
  }

  label {
    font-size: $sidr-font-size - 2;
  }

  input[type="text"],
  input[type="password"],
  input[type="date"],
  input[type="datetime"],
  input[type="email"],
  input[type="number"],
  input[type="search"],
  input[type="tel"],
  input[type="time"],
  input[type="url"],
  textarea,
  select {
    width: 100%;
    font-size: $sidr-font-size - 2;
    padding: 5px;
    margin: 0 0 10px;
    border-radius: 2px;
    border: 0;
    background: rgba($sidr-input-background-color, .1);
    color: rgba($sidr-text-color, .6);
    display: block;
    clear: both;
  }

  input[type=checkbox] {
    width: auto;
    display: inline;
    clear: none;
  }

  input[type=button],
  input[type=submit] {
    color: $sidr-background;
    background: $sidr-text-color;

    &:hover {
      background: rgba($sidr-text-color, .9);
    }
  }
}
