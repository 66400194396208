.error-container {
  height: $content-area-height;
  @include grid-row();
  @include grid-row-alignment-vertical(middle);
  @include grid-row-alignment-horizontal(center);

  .legoraf-exclamation:before {
    content: '';
    display: block;
    width: 48px;
    height: 191px;
    background: url('../images/exclamation.png') no-repeat;
    margin: 0 auto;
  }

  .title,
  .description {
    color: #363636;
  }

  .title {
    font-size: 62px;
    @include font(Verlag XLight);
    margin-top: 25px;
  }

  .description {
    font-size: 28px;
    @include font(Verlag Book);
    text-align: center;
    margin-top: 15px;
  }

  a.router {
    color: #f1592a;
  }
}