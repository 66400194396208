@mixin font($font-family) {
  font-family: '#{$font-family}', sans-serif;
}

@mixin animated-underline($color, $duration) {
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -1px;
    left: 0;
    background-color: $color;
    -webkit-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition: all $duration ease-in-out 0s;
    transition: all $duration ease-in-out 0s;
  }

  &:hover:before {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@mixin overlay($bgColor: #000000, $bgOpacity: 0.6, $height: 100%) {
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: $height;
    width: 100%;
    background-color: rgba($bgColor, $bgOpacity);
    z-index: 4;
  }

  div,
  section,
  article,
  h1,
  h2,
  h3,
  p,
  ul {
    position: relative;
    z-index: 5;
  }
}

@mixin outline-none() {
  &,
  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
}

@mixin carousel-custom-arrow($color) {
  $background-position-y: 0;

  @if $color == orange {
    $background-position-y: -70px;
  } @else if $color == white {
    $background-position-y: -35px;
  }

  .slick-prev:before {
    background-position: 0 $background-position-y;
  }

  .slick-next:before {
    background-position: -20px $background-position-y;
  }
}

@mixin carousel-custom-offset($offset) {
  .slick-prev {
    left: $offset;
  }

  .slick-next {
    right: $offset;
  }
}