.pages-list {
  &.list_carousel {
    width: 100%;
    @include carousel-custom-arrow(orange);
    @include carousel-custom-offset(5px);

    .p-carousel {
      margin-bottom: 0;
    }

    .slick-slide > div {
      margin-bottom: -5px;
    }

    .page {
      cursor: pointer;

      &-inner {
        position: relative;
        height: $page-list-carousel-item-height;
        max-height: $page-list-carousel-item-height;
        overflow-y: hidden;
        background: no-repeat center;
        background-size: cover;
        @include overlay(#000000, 0.5, 75%);

        @media (max-width: $grid-float-breakpoint-max) and (max-height: 598px) {
          height: $page-responsive-fixed-height;
          max-height: $page-responsive-fixed-height;
        }
      }

      .summary {
        @include grid-row();
        @include grid-row-alignment-vertical(middle);

        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 25%;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 0 30px;

        .title,
        .date {
          color: #ffffff;
          display: block;
        }

        .title {
          font-family: 'Open Sans Condensed', sans-serif;
          font-weight: 700;
          font-size: 16px;
          text-decoration: none;

          @media (min-width: $grid-float-breakpoint) {
            height: 44px;
          }
        }

        .date {
          font-family: 'Open Sans', sans-serif;
          font-weight: 300;
          font-size: 14px;
        }
      }

      .page-inner:after {
        @include transition(background .4s ease-in-out);
      }

      &:hover {
        .page-inner:after {
          background: rgba(0, 0, 0, 0);
        }
      }
    }
  }
}