body {
  position: relative;
  overflow-x: hidden;

  @media (min-width: $grid-float-breakpoint) and (min-height: 598px) {
    height: 100vh;
    overflow-y: hidden;
  }
}

p {
  text-align: justify;
  text-justify: inter-word;
}

.site-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;

  @media (max-width: $grid-float-breakpoint-max) and (max-height: 598px) {
    width: 100%;
    height: auto;
  }
}

.main-content {
  position: relative;
  @include make-row(0);
  height: $content-area-height;
  @include clearfix;

  @media (max-width: $grid-float-breakpoint-max) and (max-height: 598px) {
    height: auto;
    @include grid-row();
    @include grid-row-alignment-vertical(middle);
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

.page-heading {
  width: 100%;
  height: $page-heading-height;
  min-height: 46px;
  margin-bottom: 20px;
  @include grid-row();
  @include grid-row-alignment-vertical(bottom);

  @media (max-width: $grid-float-breakpoint-max) {
    height: auto;
  }
}
