$viewport-layouts: (
        "xs": (),
        "sm": ("min-width": 46rem),
        "md": ("min-width": 61rem),
        "lg": ("min-width": 71rem)
);

$columns: 12;

$gutter-width: 0;
$half-gutter-width: $gutter-width / 2;
$gutter-compensation: -1 * $half-gutter-width;

@mixin grid-row($reverse: false) {
  @include grid-row-direction($reverse);
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
}

@mixin grid-row-direction($reverse: false) {
  @if ($reverse) {
    flex-direction: row-reverse;
  } @else {
    flex-direction: row;
  }
}

@mixin grid-row-alignment-horizontal($alignment) {
  $justify: inherit;

  @if ($alignment == center) {
    $justify: center;
  } @else if ($alignment == end) {
    $justify: flex-end;
  } @else if ($alignment == start) {
    $justify: flex-start;
  }

  justify-content: $justify;
  text-align: $alignment;
}

@mixin grid-row-alignment-vertical($alignment) {
  @if ($alignment == middle) {
    align-items: center;
  } @else if ($alignment == bottom) {
    align-items: flex-end;
  } @else {
    align-items: flex-start;
  }
}

@mixin grid-row-spacing($spacing) {
  justify-content: $spacing;
}

@mixin grid-column($reverse: false) {
  @include grid-column-direction($reverse);
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: $half-gutter-width;
  padding-left: $half-gutter-width;
}

@mixin grid-column-direction($reverse:false) {
  @if ($reverse) {
    flex-direction: column-reverse;
  } @else {
    flex-direction: column;
  }
}

@mixin grid-column-span($columns-to-span: 12, $total-columns-in-row: 12) {
  @if ($columns-to-span == auto) {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;

  } @else {
    $span-percentage: $columns-to-span / $total-columns-in-row;

    flex-basis: $span-percentage * 100%;
    max-width: $span-percentage * 100%;
  }
}

@mixin grid-column-offset($columns-to-offset, $total-columns-in-row:12) {
  margin-left: ($columns-to-offset / $total-columns-in-row) * 100%;
}

@mixin grid-column-order($position) {
  @if ($position == last) {
    $position: 9999;
  } @else if ($position == first) {
    $position: -9999;
  }

  order: $position;
}