html {
  &[lang="tr"] {
    nav.navbar {
      .navbar-brand figure {
        background-image: url('../images/legoraf-logo-tr.png');
      }

      .catalogue .click {
        @include img-retina('../images/catalogue-tr.png', '../images/catalogue-tr@2x.png', 70px, 70px);
      }
    }
  }

  &[lang="en"] {
    nav.navbar {
      .navbar-brand figure {
        background-image: url('../images/legoraf-logo-en.png');
      }

      .catalogue .click {
        @include img-retina('../images/catalogue-en.png', '../images/catalogue-en@2x.png', 70px, 70px);
      }
    }
  }
}

nav.navbar {
  border: none;
  background: rgba(0, 0, 0, 0.6);
  margin-bottom: 0;

  .navbar-toggle {
    @include rotate(0deg);
    @include transition(.5s ease-in-out);
    padding: (($navbar-toggle-span-height + 1px) * 2) 15px;
    margin-top: 27px;
    margin-bottom: 27px;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: $navbar-toggle-span-height;
      width: 100%;
      background-color: $orange;
      opacity: 1;
      left: 0;
      @include border-top-radius(10px);
      @include border-bottom-radius(10px);
      @include rotate(0deg);
      @include transition(.25s ease-in-out);
      @include transform-origin(left center);

      @for $i from 0 through 2 {
        &:nth-child(#{$i + 1}) {
          top: $i * ($navbar-toggle-span-height * 2);
        }
      }
    }

    &.open {
      span:nth-child(1) {
        top: -3px;
        left: 8px;
        @include rotate(45deg);
      }

      span:nth-child(2) {
        width: 0;
        opacity: 0;
      }

      span:nth-child(3) {
        top: 18px;
        left: 8px;
        @include rotate(-45deg);
      }
    }
  }

  .navbar-brand {
    padding: 11px 30px;

    figure {
      width: 183px;
      height: 56px;
      background-repeat: no-repeat;
      background-position: center;
    }

    @media (max-width: $grid-float-breakpoint-max) {
      padding-left: 15px;
    }

    @media (max-width: $screen-md-max) {
      padding-top: 11px;
      padding-bottom: 13px;

      figure {
        width: 155px;
        background-size: 155px auto;
      }
    }
  }

  .navbar-nav {
    & > li {
      & > a {
        color: #ffffff;
        font-size: 15px;
        text-transform: uppercase;
        @include font(Verlag Book);

        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
          font-size: 13px;
        }
      }

      &.active a {
          color: $orange;
      }
    }

    &.language-change {
      & > li {
        padding-left: 5px;
        padding-right: 5px;

        & > a {
          font-size: 13px;
        }

        &.active a {
          color: $orange;
          border-bottom-color: $orange;
        }

        &:last-of-type {
          padding-right: 15px;
        }

        &:not(.active) a {
          &:before {
            display: none;
          }

          &:hover {
            color: $orange;
          }
        }
      }
    }

    @media (min-width: $grid-float-breakpoint) {
      & > li {
        padding: $nav-link-padding;
        padding-top: $navbar-padding-vertical;
        padding-bottom: $navbar-padding-vertical;

        & > a {
          @include transition(color .2s ease-in-out);
          padding: 0;

          &:hover {
            color: $orange;
          }
        }

        &:not(.active) a {
          @include animated-underline($orange, 0.4s);
        }

        &.active a {
          border-bottom: 1px solid $orange;
        }

        @media (min-width: $screen-md) and (max-width: 1045px) {
          padding-left: 5px;
          padding-right: 5px;
        }

        @media (min-width: 1046px) and (max-width: $screen-md-max) {
          padding-left: 9px;
          padding-right: 9px;
        }
      }
    }
  }

  .catalogue {
    position: relative;
    margin: 0 15px;

    .pulse {
      position: absolute;
      left: -25px;
      top: -24px;
      width: 120px;
      height: 120px;
      background-color: #f78d28;
      border-radius: 100%;
      animation: soda-catalogue-animation 1.7s infinite ease-in-out;

      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        width: 100px;
        height: 100px;
      }
    }

    .click {
      position: relative;
      display: block;
      width: 70px;
      height: 70px;
      background-repeat: no-repeat;
      margin-top: 5px;
      text-indent: -9999px;
      z-index: 9;

      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        width: 50px;
        height: 50px;
        background-size: 50px;
        margin-top: 12px;
      }
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      margin: 0 5px;
    }
  }
}