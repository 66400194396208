.form-templates {
  &.hr {
    width: 100%;
    padding: 0 50px;

    .hr-form {
      @include font(Verlag Light);
      @include make-row();

      .step {
        &.first {
          @include make-sm-column(5);
        }

        &.second,
        &.third {
          @include make-sm-column(3.5);
        }

        &.third input[type="text"] {
          margin-bottom: 5px;
        }

        .step-title {
          color: #363636;
          text-transform: uppercase;
          margin-bottom: 15px;
          @include font(Verlag Book);

          @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            font-size: 21px;
            letter-spacing: -1.5px;
          }

          @media (min-width: $screen-lg-min) {
            font-size: 21px;
          }
        }

        .form-group-row {
          @include clearfix();
        }

        .form-group {
          position: relative;
          margin-bottom: 10px;
          width: 100%;

          @media (min-width: $grid-float-breakpoint) {
            input,
            textarea {
              width: 100%;
            }

            &.full {
              width: 100%;
            }

            &.half {
              width: 49%;
            }

            &.left {
              float: left;
            }

            &.right {
              float: right;
            }
          }

          &.required:before,
          &.select-box:after {
            position: absolute;
          }

          &.required {
            &:before {
              content: '*';
              top: 2px;
              right: 5px;
              color: #d56280;
              @include font(Open Sans);
              font-weight: 400;
              z-index: 999;
            }
          }
        }

        input:not([type="submit"]),
        textarea {
          width: 100%;
          background-color: rgba(#d7d7d7, 0.9);
          color: #8b8b8b;
          font-size: 16px;
          border: 1px solid #a3a3a3;

          &::-moz-placeholder { text-transform: uppercase; }
          &:-ms-input-placeholder { text-transform: uppercase; }
          &::-webkit-input-placeholder { text-transform: uppercase; }
        }

        input:not([type="submit"]),
        textarea {
          height: auto;
          padding: 8px 15px;
          @include border-top-radius(1px);
          @include border-bottom-radius(1px);
          @include box-shadow(none);

          &:focus {
            border-color: #a3a3a3;
          }
        }

        textarea {
          width: 100%;
          @include outline-none();
          @include resizable(none);
        }

        @media (max-height: 771px) {
          input:not([type="submit"]),
          textarea,
          .select2-container--default .select2-selection__rendered {
            padding-top: 5px !important;
            padding-bottom: 5px !important;
          }

          textarea {
            max-height: 9vh;
          }
        }
      }

      button[type="submit"] {
        font-weight: 300;
        text-transform: uppercase;
        @include font(Open Sans);
        @include outline-none();
        @include button-size(10px, 40px, 16px, normal, 5px);
        @include button-variant(#dcdcdc, #ff6633, transparent);
        @include box-shadow(0 6px 0 #d83e1b)
      }

      .select2-container {
        .select2-selection--single {
          &:focus {
            @include outline-none();
          }
        }

        &--default {
          .select2-selection--single {
            height: auto;
            background-color: rgba(215, 215, 215, 0.9);
            border: 1px solid #a3a3a3;
            color: #8b8b8b;
            @include border-top-radius(0);
            @include border-bottom-radius(0);

            .select2-selection__arrow {
              width: 22px;
              height: 38px;

              b {
                border-color: #d56e67 transparent transparent transparent;
                border-width: 8px 8px 0 8px;
                margin-left: -22px;
                margin-top: -4px;
              }
            }

            .select2-selection__rendered {
              padding: 8px 40px 8px 15px;
              font-size: 16px;
              line-height: inherit;
            }
          }
        }
      }
    }

    .reminder-submit {
      @include make-md-column(12);

      .reminder {
        height: 45px;
        line-height: 45px;
        float: left;
        @include font(Open Sans);
        font-weight: 300;
        vertical-align: middle;
        color: #848484;
      }
    }

    @media (max-width: $grid-float-breakpoint-max) {
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 30px;
    }
  }
}

.select2-selection__placeholder {
  text-transform: uppercase;
}

.select2-results__option--highlighted[aria-selected] {
  background-color: #d56e67 !important;
}

.form-group.has-error {
  input,
  textarea,
  .select2-container--default .select2-selection--single {
    border-color: #d56280 !important;
  }
}