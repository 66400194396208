.jspScrollable {
  @include outline-none();
}

.jspContainer
{
  overflow: hidden;
  position: relative;
}

.jspPane
{
  position: absolute;
}

.jspVerticalBar
{
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 100%;
}

.jspHorizontalBar
{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 12px;
}

.jspCap
{
  display: none;
}

.jspHorizontalBar .jspCap
{
  float: left;
}

.jspTrack
{
  background: #9b9b9b;
  position: relative;
  @include border-top-radius(5px);
  @include border-bottom-radius(5px);
}

.jspDrag
{
  background: #737373;
  position: relative;
  top: 0;
  left: 0;
  cursor: pointer;
  @include border-top-radius(5px);
  @include border-bottom-radius(5px);
}

.jspHorizontalBar .jspTrack,
.jspHorizontalBar .jspDrag
{
  float: left;
  height: 100%;
}

.jspVerticalBar .jspArrow:focus
{
  outline: none;
}

.jspCorner
{
  background: #eeeef4;
  float: left;
  height: 100%;
}

* html .jspCorner
{
  margin: 0 -3px 0 0;
}
