.contact-details {
  &.address_frame {
    width: 100%;
    @include clearfix();

    @media (max-height: 699px) and (min-width: $screen-lg) {
      .page-heading {
        margin-bottom: 10px;
      }
    }

    .address {
      @include font(Verlag Light);
      font-size: 18px;
      line-height: 3.4vh;
      color: #dcdcdc;

      .dl-horizontal {
        margin-top: 1.5vh;

        dt {
          text-align: left;
        }

        dd:not(:last-of-type) {
          margin-bottom: 0.5vh;
        }
      }

      @media (max-width: $grid-float-breakpoint-max) {
        line-height: normal;
      }
    }

    .sub-heading {
      margin-bottom: 1.5vh;

      @media (max-width: $grid-float-breakpoint-max) {
        margin-bottom: 20px;
      }

      @media (max-height: 699px) and (min-width: $screen-lg) {
        margin-top: -10px;
      }
    }

    .contact-form {
      @include font(Verlag Light);
      font-size: 16px;
      @include clearfix();

      .form-group {
        width: 100%;
        margin-left: 0;
        margin-right: 0;

        @media (max-height: 825px) and (min-width: $grid-float-breakpoint) {
          &.left,
          &.right {
            width: 50%;
          }

          .form-control {
            width: 98%;
          }

          &.left,
          &.left .form-control {
            float: left;
          }

          &.right,
          &.right .form-control {
            float: right;
          }
        }

        @media (max-height: 699px) and (min-width: $screen-lg) {
          margin-bottom: 10px;
        }
      }

      input[type="text"],
      input[type="email"],
      textarea {
        background-color: #c6c6c6;
        border: 1px transparent solid;
        color: #464646;
        padding-left: 23px;
        padding-right: 23px;
        @include placeholder(#464646);
        @include box-shadow(none);

        @media (max-width: $screen-xs-max) {
          font-size: 16px;
        }
      }

      input[type="text"],
      input[type="email"] {
        height: 6vh;

        @media (max-width: $grid-float-breakpoint-max) {
          height: auto;
          padding: 10px;
        }
      }

      textarea {
        width: 100%;
        padding-top: 3vh;
        padding-bottom: 3vh;
        @include resizable(none);
        @include outline-none();

        @media (max-width: $grid-float-breakpoint-max) {
          padding: 10px;
          -webkit-appearance: none;
          -webkit-border-radius: 0;
        }

        @media (max-height: 699px) and (min-width: $screen-lg) {
          padding-top: 2vh;
          padding-bottom: 2vh;
        }
      }

      button[type="submit"] {
        float: right;
        border: none;
        background-color: #363636;
        color: #c6c6c6;
        padding-left: 3vw;
        padding-right: 3vw;
        @include outline-none();
      }

      .form-bottom {
        .validation-messages {
          @include make-xs-column(8, 0);
        }
        .button-holder {
          @include make-xs-column(4, 0)
        }
      }
    }

    @media (max-width: $grid-float-breakpoint-max) {
      padding-bottom: 30px;
    }
  }

  &.map_frame {
    width: 100%;
    height: inherit;

    #map.contact {
      width: 100%;
      height: inherit;

      @media (max-width: $grid-float-breakpoint-max) {
        display: none;
      }
    }

    .map-static {
      width: 100%;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.validation-messages {
  max-height: 26px;
  overflow-y: hidden;
  margin-top: 0;
  line-height: 20px !important;
  padding-top: 6px;

  .help-block {
    color: #dcdcdc;
    margin: 0;
  }

  small {
    font-size: 100%;
  }
}

.button-holder {
  @include clearfix();
}