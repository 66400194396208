$orange: #fd4f00;
$headings-color: #848484;

// Typography
$font-family-base: 'Open Sans', sans-serif;
$headings-font-family: 'Verlag Light', sans-serif;

// Navbar
$navbar-height: 80px;
$nav-link-hover-bg: transparent;
$navbar-toggle-span-height: 4px;

$panel-selector: cd-panel;
$panel-transition-duration: .3s;

$page-responsive-fixed-height: 300px;

$grid-float-breakpoint: 992px;
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);

// Sidr
$sidr-font-family: "Verlag Book", sans-serif;
$sidr-font-size: 15px;
$sidr-background: #333;
$sidr-background-shadow-color: #222;
$sidr-text-color: #ffffff;
$sidr-box-shadow-color: #000;
$sidr-input-background-color: #000;
$sidebar-width: 260px;
$sidebar-width-neg: -260px;

$footer-height: 30px;
$border-radius-base: 0;
$dl-horizontal-offset: 80px;
$dl-horizontal-breakpoint: 0;

$back-button-animation-duration: 1s;
$social-media-horizontal-padding: 10px;

// İçerik alanı hesaplamaları
$content-area-height: calc(100vh - (#{$navbar-height} + #{$footer-height}));
$page-heading-height: calc((100vh - (#{$navbar-height} + #{$footer-height})) / 5);
$page-content-height: calc(4 * (100vh - (#{$navbar-height} + #{$footer-height})) / 5 - 40px);
$page-list-carousel-item-height: calc((100vh - (#{$navbar-height} + #{$footer-height})) / 2);
$page-list-scrollable-content-height: calc(100vh - (#{$navbar-height} + #{$footer-height} + 120px));