@keyframes soda-catalogue-animation {
  0% {
    @include scale(0);
  }

  100% {
    @include scale(1.0);
    @include opacity(0);
  }
}

@-webkit-keyframes draw-border-circle {
  from {
    stroke-dashoffset: 537;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes draw-border-circle {
  from {
    stroke-dashoffset: 537;
  }

  to {
    stroke-dashoffset: 0;
  }
}