.slick-slide {
  @include outline-none();
}

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 35px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 9;
  &:hover, &:focus {
    outline: none;
    background: transparent;
    color: transparent;
    &:before {
      //opacity: $slick-opacity-on-hover;
    }
  }
  &.slick-disabled {
    display: none !important;
  }
}

.slick-prev:before,
.slick-next:before {
  content: '';
  display: block;
  height: 35px;
  width: 20px;
  background: url('../images/carousel-arrows.png') no-repeat;
  font-size: 20px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -35px;
  &:before {
    background-position: 0 0;
  }
}

.slick-next {
  right: -35px;
  &:before {
    background-position: -20px 0;
  }
}

.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;

  li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    padding: 2px;
    cursor: pointer;
    border: 1px solid transparent;

    button {
      border: 0;
      background: #848484;
      display: block;
      height: 10px;
      width: 10px;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      @include border-top-radius(50%);
      @include border-bottom-radius(50%);

      &:hover, &:focus {
        @include outline-none();
      }
    }

    .carousel-dot  > canvas {
      position: absolute;
      top: -2px;
      left: -2px;
    }
  }
}
