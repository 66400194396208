.full-width,
.five-of-twelve,
.seven-of-twelve,
.wireframe-3 .main-menu,
.wireframe-3 .sub-menu,
.wireframe-3 .sub-menu-gallery {
  height: inherit;
  @include grid-column();
  @include clearfix;

  @media (max-width: $grid-float-breakpoint-max) {
    height: auto;
  }
}

.full-width {
  @include make-md-column(12, 0);
}

.five-of-twelve {
  background-color: rgba(#353535, 0.8);
  @include make-md-column(4.7, 0);

  @media (max-width: $grid-float-breakpoint-max) {
    @include grid-column();
    @include grid-column-order(last);
  }
}

.seven-of-twelve {
  @include make-md-column(7.3, 0);
  @include grid-row();
  @include grid-row-alignment-vertical(middle);
  @include grid-row-alignment-horizontal(center);

  @media (max-width: $grid-float-breakpoint-max) {
    @include grid-column-order(first);
  }
}

@media (max-width: $grid-float-breakpoint-max) {
  .full-width,
  .five-of-twelve,
  .seven-of-twelve {
    width: 100vw;
  }
}

.wireframe-1 {
  .file-manager {
    &.references {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}

.menu-iletisim {
  .five-of-twelve {
    background-color: #464646;
  }
}

.five-of-twelve {
  color: #ffffff;
  padding-left: 30px;
  padding-right: 30px;

  h1, h2, h3, h4, h5, h6 {
    color: #ffffff;
  }

  @media (max-width: $grid-float-breakpoint-max) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.wireframe-2 {
  .file-manager {
    &.subpage_carousel {
      @media (min-width: $grid-float-breakpoint) {
        padding-left: 50px;
        padding-right: 50px;
      }
    }
  }
}

.back-button {
  position: relative;
  top: 30px;
  left: 0;
  z-index: 99;

  & > canvas {
    position: absolute;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 13px;
    width: 13px;
    height: 1px;
    background-color: #ffffff;
  }

  &:before {
    top: 15px;
    @include rotate(-45deg);
  }

  &:after {
    top: 24px;
    @include rotate(45deg);
  }

  &:not(.cd-panel-close) {
    @media (max-height: 700px) {
      top: 2vh;
    }
  }
}

@media (max-width: $grid-float-breakpoint-max) {
  .back-button:not(.cd-panel-close) {
    top: 0;
    margin: 30px 0 10px 0;
    display: inline-block;

    & > canvas {
      position: static;
    }
  }
}
