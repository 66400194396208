footer {
  height: $footer-height;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0 15px;
  @include clearfix();

  .contact-details,
  .social-accounts {
    height: inherit;
    @include grid-row();
    @include grid-row-alignment-vertical(middle);
  }

  .contact-details {
    @include font(Open Sans);
    @include make-xs-column(12);
    @include make-sm-column(9, 0);
    @include make-md-column(10.7, 0);
    color: #ffffff;

    @media (min-width: $screen-md-min) {
      font-size: .8vw;
    }
  }

  .social-accounts {
    @include make-xs-column(12);
    @include make-sm-column(3, 0);
    @include make-md-column(1.3, 0);
    @include grid-row-alignment-horizontal(end);

    a {
      color: #cdcdcd;
      margin-left: $social-media-horizontal-padding;
      margin-right: $social-media-horizontal-padding;
      @include transition(color .3s ease-in-out);

      &:first-of-type {
        margin-left: 0;
        margin-right: $social-media-horizontal-padding;
      }

      &:last-of-type {
        margin-left: $social-media-horizontal-padding;
        margin-right: 0;
      }

      &:hover {
        color: $orange;
      }
    }

    @media (max-width: $screen-xs-max) {
      @include grid-row-alignment-horizontal(start);
    }
  }

  @media (max-width: $screen-sm) {
    font-size: 13px;
    height: auto;
    padding: 5px 15px;
  }
}