.submenus {
  &.full_page {
    width: 100%;
    @include container-fixed(0);
    @include carousel-custom-arrow(white);
    @include carousel-custom-offset(5px);

    .carousel {
      margin-bottom: 0;
    }

    .submenu {
      position: relative;
      height: $content-area-height;
      @include overlay(#000000, .4);
      @include grid-row();
      @include grid-row-alignment-vertical(middle);
      padding-left: 2vw;
      padding-right: 2vw;
      overflow: hidden;

      &:after {
        @include transition(background-color .7s ease-in-out);
      }

      & > .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: $content-area-height;
        background: no-repeat center;
        background-size: cover;
        z-index: 3;
        @include transition(transform .7s ease-in-out);

        @media (max-width: $grid-float-breakpoint-max) and (max-height: 598px) {
          height: ($page-responsive-fixed-height * 4 / 3);
        }
      }

      h2 a,
      p a {
        color: #ffffff;
        text-decoration: none;
      }

      h2 {
        @include font(Verlag XLight);
        font-size: 42px;
        margin-bottom: 25px;
        height: 100px;
        @include grid-row();
        @include grid-row-alignment-vertical(bottom);
      }

      p {
        max-height: 0;
        overflow: hidden;
        @include font(Verlag Book);
        font-size: 20px;
        @include transition(max-height 1s ease-in-out);
      }

      .more {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 40px;
        background-color: rgba(255, 255, 255, 0.3);
        border: 2px solid #ffffff;
        @include border-top-radius(50%);
        @include border-bottom-radius(50%);
        text-indent: -9999px;

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 18px;
          height: 11px;
          background: url('../images/more-caret.png') no-repeat;
          margin: 11px 9px;
          @include transition(all .5s ease-in-out);

          @media (max-width: $grid-float-breakpoint-max) {
            margin-top: 13px;
            @include rotate(90deg);
          }
        }
      }

      &:hover {
        @media (min-width: $grid-float-breakpoint) {
          &:after {
            background-color: rgba(#000000, 0.2);
          }

          p {
            max-height: 60vh;
          }

          & > .background {
            @include scale(1.2);
          }

          .more:after {
            margin-top: 13px;
            @include rotate(180deg);
          }
        }
      }

      @media (max-width: $screen-sm-max) {
        padding-left: 50px;
        padding-right: 50px;
      }

      @media (max-width: $grid-float-breakpoint-max) and (max-height: 598px) {
        height: ($page-responsive-fixed-height * 4 / 3);
      }
    }
  }

  &.dynamic_list {
    position: relative;
    @include carousel-custom-arrow(white);
    @include clearfix();
    @include transition(transform 1s ease-in-out);

    .main-menu {
      @include make-md-column(3, 0);
      padding-left: 30px;
      padding-right: 30px;
      z-index: 9;

      .page-heading {
        height: auto;
        min-height: initial;
        margin-top: 50px;

        h1 {
          color: #ffffff;
        }

        @media screen and (max-height: 700px) {
          margin-top: 30px;
        }
      }

      .back-button {
        display: block;
        height: 40px;
      }

      ul.submenus-vertical-list {
        margin: 0;
        padding: 0;
        list-style: none;
        height: $page-content-height;
        max-height: $page-content-height;

        li {
          position: relative;
          color: #ffffff;
          @include font(Verlag Light);
          font-size: 24px;
          cursor: pointer;
          margin-bottom: 15px;

          @media (min-width: $grid-float-breakpoint) {
            @include animated-underline(#fd4f00, .5s);

            &.is-selected {
              @include font(Verlag Book);

              &:before {
                -webkit-transform: scale(1, 1);
                -ms-transform: scale(1, 1);
                transform: scale(1, 1);
              }
            }
          }
        }

        @media (max-width: $grid-float-breakpoint-max) and (max-height: 598px) {
          height: 400px;
          max-height: 400px;
        }
      }

      @media (max-width: $grid-float-breakpoint-max) {
        left: 0;
      }
    }

    .sub-menu {
      @include make-md-column(4, 0);
      padding-left: 40px;
      padding-right: 40px;
      z-index: 8;

      .file-manager.absolute_background .absolute {
        @include overlay($bgOpacity: 0.4);
      }

      .navigation {
        @include carousel-custom-offset(-20px);

        .slick-next,
        .slick-prev {
          margin-top: 0;

          &:before {
            @include scale(0.5);
          }
        }

        .carousel-item {
          margin: 7.5px;
          cursor: pointer;
          overflow: hidden;

          .item-magnify {
            position: relative;
            @include overlay($bgOpacity: 0.3);

            &:before {
              content: '\f002';
              position: absolute;
              top: 50%;
              left: 50%;
              font-family: FontAwesome;
              font-size: 50px;
              color: #ffffff;
              @include opacity(0.5);
              @include translate(-50%, -50%);
              @include transition(opacity .3s ease-in-out);
              z-index: 6;
            }

            &:after {
              @include opacity(1);
              @include transition(opacity .3s ease-in-out);
            }
          }

          img {
            @include img-responsive(inline-block);
            @include transition(all .3s ease-in-out);
          }

          &:hover img {
            @include scale(1.1);
          }

          &.slick-current {
            .item-magnify {
              &:before,
              &:after {
                @include opacity(0);
              }
            }
          }
        }
      }

      ul.super-sub-menu-list {
        list-style: none;
        margin: $page-heading-height 0 0 0;
        padding: 0;

        li {
          color: #ffffff;
          @include font(Verlag Light);
          font-size: 24px;
          margin-bottom: 15px;
          @include opacity(.7);
          @include transition(opacity .3s ease-in-out);

          .title {
            cursor: pointer;
          }

          .navigation {
            margin: 0;
            @include scaleY(0);
            @include transform-origin(top);
            @include transition(all 0.6s ease-in-out);

            &.slick-initialized {
              @include scaleY(1);
            }

            @media (max-width: $grid-float-breakpoint) {
              display: none !important;
            }
          }

          @media (min-width: $grid-float-breakpoint) {
            &:hover,
            &.is-selected {
              @include opacity(1);
            }

            &.is-selected {
              font-size: 32px;
            }
          }
        }
      }

      .back-button {
        left: -10px;
        position: relative;
      }

      @media (max-width: $grid-float-breakpoint-max) {
        left: 100vw;
      }
    }

    .menu-gallery {
      @include make-md-column(5, 0);
      @include carousel-custom-arrow(orange);
      z-index: 7;

      @media (max-width: $grid-float-breakpoint-max) {
        left: 200vw;
      }

      .back-button {
        position: absolute;
        left: 30px;
      }
    }

    &.no-submenus {
      .sub-menu {
        display: none;
      }

      .menu-gallery {
        @include make-md-column(9, 0);

        @media (max-width: $grid-float-breakpoint-max) {
          left: 100vw;
        }
      }
    }

    @media (min-width: $grid-float-breakpoint) {
      & > .sub-menu,
      &.menu-gallery {
        .back-button {
          display: none;
        }
      }
    }

    @media (max-height: 598px) {
      height: 600px;

      .main-menu,
      .sub-menu,
      .menu-gallery {
        height: 600px;
      }

      .file-manager.absolute_background .absolute {
        height: 600px;
      }
    }

    @media (max-width: $grid-float-breakpoint-max) {
      .main-menu,
      .sub-menu,
      .menu-gallery {
        position: absolute;
        width: 100vw;
        top: 0;
      }

      .sub-menu,
      .menu-gallery {
        .back-button {
          width: 40px;
          height: 40px;
          border: 1px solid #fff;
          @include border-top-radius(50%);
          @include border-bottom-radius(50%);
        }
      }

      &.mobile-detail {
        @include translate3d(-100%, 0, 0);

        &.second-diffraction {
          @include translate3d(-200%, 0, 0);
        }
      }
    }
  }
}